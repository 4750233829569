/**
 * Created by osirvent on 16/02/2017.
 */
angular
    .module('annexaApp')
    .controller('SecController', ['$scope', '$rootScope', 'HeaderService', '$state', function ($scope, $rootScope, HeaderService, $state) {
        HeaderService.onChangeState($scope, function(message) {
        	if(_.contains(['annexa.sec.sessions', 'annexa.sec.proposals', 'annexa.sec.decrees'], message.state.name)) {
	            $rootScope.subHeadTabs = [
	                {
	                    state: 'annexa.sec.proposals',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.proposals' ? 'active' : ''),
	                    name: 'global.sec.proposals.title',
	                    permissions: ['view_proposals', 'review_proposals', 'valid_proposals', 'accept_proposals', 'amendment_proposals', 'cancel_proposals']
	                },
	                {
	                    state: 'annexa.sec.sessions',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.sessions' ? 'active' : ''),
	                    name: 'global.sec.literals.sessions',
	                    permissions: ['view_sessions']
	                },
	                {
	                    state: 'annexa.sec.decrees',
	                    type: 'breadDivButton ' + (message.state.name == 'annexa.sec.decrees' ? 'active' : ''),
	                    name: 'global.sec.literals.decrees',
	                    permissions: ['view_decrees']
	                }
	            ];
        	}
        });

        HeaderService.changeState($state.current);
    }])
    .controller('SecProposalsController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilterFactory', '$filter', 'Language', 'SecFactory', 'SecModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'DialogsFactory', 'GlobalDataFactory', 'apiAdmin', '$http', 'HelperService','RestService', '$stateParams', 'ErrorFactory','AnnexaPermissionsFactory','$timeout', function($scope, $rootScope, HeaderService, $state, TableFilterFactory, $filter, Language, SecFactory, SecModals, AnnexaFormlyFactory, RouteStateFactory, DialogsFactory, GlobalDataFactory, apiAdmin, $http, HelperService,RestService,$stateParams, ErrorFactory, AnnexaPermissionsFactory, $timeout) {
    	$scope.showview = false;
        if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
                $rootScope.app.configuration.validation_circuit_secretary.value) {
        	$scope.idActiveTab = 'review';
        	$scope.activeTab = 0;
        	$scope.showview = true;
        	$scope.showTabAll = true;
        	$scope.showTabReview = false;
        	$scope.showTabValidate = false;
        	$scope.showTabAccept = false;
        	$scope.showDataTableAll = false;
        	$scope.showDataTableReview = true;
        	$scope.showDataTableValidate = false;
        	$scope.showDataTableAccept = false;
        	var permisionAll = AnnexaPermissionsFactory.havePermission('view_proposals', true);
        	if(permisionAll) {
            	$scope.showTabReview = true;
            	$scope.showTabValidate = true;
            	$scope.showTabAccept = true;

            	if($rootScope.$state.params && $rootScope.$state.params.tab) {
            		if($rootScope.$state.params.tab == 'validate') {
            			$scope.idActiveTab = 'validate';
            			$scope.activeTab = 1;
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = true;
                    	$scope.showDataTableAccept = false;
            		} else if($rootScope.$state.params.tab == 'accept') {
            			$scope.idActiveTab = 'accept';
            			$scope.activeTab = 2;
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = true;
            		} else if($rootScope.$state.params.tab == 'all') {
            			$scope.idActiveTab = 'all';
            			$scope.activeTab = 3;
            			$scope.showDataTableAll = true;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = false;
            		}
            	}
        	} else {
            	var permisionReview = AnnexaPermissionsFactory.havePermission('review_proposals', true);
            	var permisionValid = AnnexaPermissionsFactory.havePermission('valid_proposals', true);
            	var permisionAccept = AnnexaPermissionsFactory.havePermission('accept_proposals', true);
            	if(permisionReview) {
                	$scope.showTabReview = true;
            	}
            	if(permisionValid) {
                	$scope.showTabValidate = true;
            	}
            	if(permisionAccept) {
                	$scope.showTabAccept = true;
            	}
            	if($rootScope.$state.params && $rootScope.$state.params.tab) {
            		if($rootScope.$state.params.tab == 'review') {
            			$scope.idActiveTab = 'review';
            			$scope.activeTab = 0;
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = true;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = false;
            		} else if($rootScope.$state.params.tab == 'validate') {
            			$scope.idActiveTab = 'validate';
            			$scope.activeTab = 0;
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = true;
                    	$scope.showDataTableAccept = false;
            			if($scope.showTabReview) {
            				$scope.activeTab = 1;
            			}
            		} else if($rootScope.$state.params.tab == 'accept') {
            			$scope.idActiveTab = 'accept';
            			$scope.activeTab = 0;
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = true;
            			if($scope.showTabReview && $scope.showTabValidate) {
            				$scope.activeTab = 2;
            			} else if ($scope.showTabReview && !$scope.showTabValidate) {
            				$scope.activeTab = 1;
            			}
            		} else if($rootScope.$state.params.tab == 'all') {
            			$scope.idActiveTab = 'all';
            			$scope.activeTab = 0;
            			$scope.showDataTableAll = true;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = false;
            			if($scope.showTabReview && $scope.showTabValidate && $scope.showTabAccept) {
            				$scope.activeTab = 3;
            			} else if ($scope.showTabReview && $scope.showTabValidate && !$scope.showTabAccept) {
            				$scope.activeTab = 2;
            			} else if ($scope.showTabReview && !$scope.showTabValidate && !$scope.showTabAccept) {
            				$scope.activeTab = 1;
            			}
            		}
            	} else {
            		if(!permisionReview && !permisionValid && !permisionAccept) {
            			$scope.idActiveTab = 'all';
            			$scope.showDataTableAll = true;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = false;
            		} else if(!permisionReview && permisionValid) {
            			$scope.idActiveTab = 'validate';
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = true;
                    	$scope.showDataTableAccept = false;
            		} else if(!permisionReview && !permisionValid && permisionAccept) {
            			$scope.idActiveTab = 'accept';
            			$scope.showDataTableAll = false;
                    	$scope.showDataTableReview = false;
                    	$scope.showDataTableValidate = false;
                    	$scope.showDataTableAccept = true;
            		}
            	}
        	}
        	$scope.proposalSelectedTab = function (tab) {
        		$scope.showDataTableAll = false;
            	$scope.showDataTableReview = false;
            	$scope.showDataTableValidate = false;
            	$scope.showDataTableAccept = false;
            	var waitTab = function(){
            		$scope.idActiveTab = tab;
            		if(tab == 'review') {
                    	$scope.showDataTableReview = true;
            		}else if(tab == 'validate') {
                    	$scope.showDataTableValidate = true;
            		} else if(tab == 'accept') {
                    	$scope.showDataTableAccept = true;
            		} else if(tab == 'all') {
            			$scope.showDataTableAll = true;
            		}
            	}
            	$timeout(function() {waitTab()}, 200);
        	}
        }
    }])
    .controller('SecEditProposalController', ['$scope', '$rootScope', 'HeaderService', '$state', 'AnnexaEntityFactory', 'SecFactory', 'DccumentsFactory', function ($scope, $rootScope, HeaderService, $state, AnnexaEntityFactory, SecFactory, DccumentsFactory) {
        $scope.proposal = SecFactory.proposal;
                
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.proposals.edit' && SecFactory.proposal) {
                $rootScope.subHeadButtons = [];

                if(_.contains(['PENDING','REVIWED','VALIDATED','ACCEPTED'], SecFactory.proposal.state)){
                	if(!(_.contains(['ACCEPTED'], SecFactory.proposal.state) && SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE')){
	                	var diligenceActions = [];
						$rootScope.subHeadButtons.push(new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.literals.actions', undefined, undefined, undefined, undefined, undefined, diligenceActions, 'dropdown-menu primary pull-right'));
						
						var generateAmendment = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.sec.streamline.amendmentProposal', undefined, 'generateAmendment').setPermissions(['view_proposals', 'amendment_proposals']);
						var cancelProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.cancel', undefined, 'cancelProposal').setPermissions(['view_proposals', 'cancel_proposals']);

						if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.validation_circuit_secretary &&
				                $rootScope.app.configuration.validation_circuit_secretary.value) {
							if(SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.circuitReview && _.contains(['PENDING'], SecFactory.proposal.state)) {
								var showReview = true;
								if(SecFactory.proposal.organs != null && SecFactory.proposal.organs.length > 0) {
									showReview = false;
		            				_.forEach(SecFactory.proposal.organs, function(organ){
		            					if(organ.actual) {
		            						if(organ.organ != null && organ.organ.reviewProposalUsers != null && organ.organ.reviewProposalUsers.length > 0) {
		                            			_.forEach(organ.organ.reviewProposalUsers, function(rpu){
		                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
		                            					showReview = true;
		                            				}
		                                    	});
		                            		} else if(organ.organ != null && organ.organ.reviewProposalProfiles != null && organ.organ.reviewProposalProfiles.length > 0) {
		                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
		                            			_.forEach(organ.organ.reviewProposalProfiles, function(rpp){
		                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
		                            					showReview = true;
		                            				}
		                                    	});
		                            		} else {
		                            			showReview = true;
		                            		}
		            					}
		                        	});
		            			}
								if(showReview) {
									var reviewProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.review', undefined, 'reviewProposal').setPermissions(['view_proposals', 'review_proposals']);
									diligenceActions.push(reviewProposal);
								}
							}
							if(SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.circuitValidate){
								var showValidate = false;
								if(SecFactory.proposal.proposalType.circuitReview && _.contains(['REVIWED'], SecFactory.proposal.state)) {
									showValidate = true;
								} else if(!SecFactory.proposal.proposalType.circuitReview && _.contains(['PENDING'], SecFactory.proposal.state)) {
									showValidate = true;
								}
								if(showValidate) {
									if(SecFactory.proposal.organs != null && SecFactory.proposal.organs.length > 0) {
										showValidate = false;
			            				_.forEach(SecFactory.proposal.organs, function(organ){
			            					if(organ.actual) {
			            						if(organ.organ != null && organ.organ.validateProposalUsers != null && organ.organ.validateProposalUsers.length > 0) {
			                            			_.forEach(organ.organ.validateProposalUsers, function(rpu){
			                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
			                            					showValidate = true;
			                            				}
			                                    	});
			                            		} else if(organ.organ != null && organ.organ.validateProposalProfiles != null && organ.organ.validateProposalProfiles.length > 0) {
			                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
			                            			_.forEach(organ.organ.validateProposalProfiles, function(rpp){
			                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
			                            					showValidate = true;
			                            				}
			                                    	});
			                            		} else {
			                            			showValidate = true;
			                            		}
			            					}
			                        	});
			            			}
								}
								if(showValidate) {
									var validateProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.validate', undefined, 'validateProposal').setPermissions(['view_proposals', 'valid_proposals']);
									diligenceActions.push(validateProposal);
								}
							}
							if(SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.circuitAccept){
								var showAccept = false;
								if(SecFactory.proposal.proposalType.circuitValidate && _.contains(['VALIDATED'], SecFactory.proposal.state)) {
									showAccept = true;
								} else if(!SecFactory.proposal.proposalType.circuitValidate && SecFactory.proposal.proposalType.circuitReview && _.contains(['REVIWED'], SecFactory.proposal.state)) {
									showAccept = true;
								} else if(!SecFactory.proposal.proposalType.circuitValidate && !SecFactory.proposal.proposalType.circuitReview && _.contains(['PENDING'], SecFactory.proposal.state)) {
									showAccept = true;
								}
								if(showAccept) {
									if(SecFactory.proposal.organs != null && SecFactory.proposal.organs.length > 0) {
										showAccept = false;
			            				_.forEach(SecFactory.proposal.organs, function(organ){
			            					if(organ.actual) {
			            						if(organ.organ != null && organ.organ.acceptProposalUsers != null && organ.organ.acceptProposalUsers.length > 0) {
			                            			_.forEach(organ.organ.acceptProposalUsers, function(rpu){
			                            				if(rpu.user != null && rpu.user.id != null && rpu.user.id == $rootScope.LoggedUser.id) {
			                            					showAccept = true;
			                            				}
			                                    	});
			                            		} else if(organ.organ != null && organ.organ.acceptProposalProfiles != null && organ.organ.acceptProposalProfiles.length > 0) {
			                            			var userProf = $linq($rootScope.LoggedUser.userProfiles).select("x => x.profile.id").toArray();
			                            			_.forEach(organ.organ.acceptProposalProfiles, function(rpp){
			                            				if(rpp.profile != null && rpp.profile.id != null && $linq(userProf).contains(rpp.profile.id)) {
			                            					showAccept = true;
			                            				}
			                                    	});
			                            		} else {
			                            			showAccept = true;
			                            		}
			            					}
			                        	});
			            			}
								}
								if(showAccept) {
									var aproveProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.accept', undefined, 'aproveProposal').setPermissions(['view_proposals', 'accept_proposals']);
									diligenceActions.push(aproveProposal);
								}
							} else if(!SecFactory.proposal.proposalType.circuitValidate && !SecFactory.proposal.proposalType.circuitReview 
									&& !SecFactory.proposal.proposalType.circuitAccept && _.contains(['PENDING','REVIWED','VALIDATED'], SecFactory.proposal.state)) {
								var aproveProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.accept', undefined, 'aproveProposal').setPermissions(['view_proposals', 'accept_proposals']);
								diligenceActions.push(aproveProposal);
							}
						} else {
							var aproveProposal = new HeadButton('btn primary breadDivButton', undefined, '#edit-proposal', undefined, 'global.literals.accept', undefined, 'aproveProposal').setPermissions(['view_proposals', 'accept_proposals']);
							if(_.contains(['PENDING','REVIWED','VALIDATED'], SecFactory.proposal.state)) {
								diligenceActions.push(aproveProposal);
							}
						}
						
						if(_.contains(['PENDING','REVIWED','VALIDATED'], SecFactory.proposal.state)) {
							diligenceActions.push(generateAmendment);
							diligenceActions.push(cancelProposal);
						   	if(SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE' && SecFactory.proposal.decreeDocument){
						   		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.sec.literals.editDecree', undefined, 'editDecreeDocument'));
						   	}
						}else if(_.contains(['ACCEPTED'], SecFactory.proposal.state)){
							if(!(SecFactory.proposal && SecFactory.proposal.proposalType && SecFactory.proposal.proposalType.proposalTypeSubtype == 'DECREE' && SecFactory.proposal.decreeDocument)){
							   	diligenceActions.push(generateAmendment);
							   	diligenceActions.push(cancelProposal);
							}
					    }
                	}
				}
            }
        });

        HeaderService.changeState($state.current, true);
        
    }])
    .controller('SecSessionsController',['$scope', '$rootScope', 'HeaderService', '$state', 'SecFactory', 'Language', 'TableFilterFactory', '$filter', 'AnnexaFormlyFactory', 'SecModals', 'RestService', 'RouteStateFactory', 'GlobalDataFactory', 'HelperService', 'DialogsFactory', '$stateParams', function ($scope, $rootScope, HeaderService, $state, SecFactory, Language, TableFilterFactory, $filter, AnnexaFormlyFactory, SecModals, RestService, RouteStateFactory, GlobalDataFactory, HelperService, DialogsFactory,$stateParams) {
        $scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });

        $scope.alerts = [];
        
        $scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.sessions') {
                $rootScope.subHeadButtons = [
                	new HeadButtonSearch("#tableFilter"),
                    new HeadButton('btn primary breadDivButton m-r-sm', undefined, '#sec-principal', 'fa fa-plus', 'global.sec.literals.newSession', undefined, 'newSession').setPermissions('view_sessions')
                ]
				$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#sec-principal', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));
            }
        });

        HeaderService.changeState($state.current);

        $scope.newSession = function() {
            var modal = SecModals.sessionNew;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.row1 = {};
            modal.annexaFormly.model.row2 = { sessionFirstDate: new Date(), sessionFirstTime: new Date() };
            var dt = new Date();
            dt.setMinutes( dt.getMinutes() + 30 );
            modal.annexaFormly.model.row3 = { sessionSecondDate: dt, sessionSecondTime: dt }
            modal.annexaFormly.options = {};

            AnnexaFormlyFactory.showModal('modalNesSession', modal, $scope.saveSession, false);
        };

        $scope.saveSession = function (session) {
            var modal = this;

            var meetingLocation = undefined;
            var attendees = [];


            var model = {
                state: 'PENDING',
                organ: session.annexaFormly.model.row1.organ ? { id: session.annexaFormly.model.row1.organ } : null,
                sessionType: session.annexaFormly.model.row1.sessionType ? { id: session.annexaFormly.model.row1.sessionType } : null,
                meetingLocation: ((session.annexaFormly.model.row4.meetingLocation)?((session.annexaFormly.model.row4.meetingLocation.id)?{id:session.annexaFormly.model.row4.meetingLocation.id}:{id:session.annexaFormly.model.row4.meetingLocation}): null),
                sessionDateFirstConvene: session.annexaFormly.model.row2.sessionFirstDate && session.annexaFormly.model.row2.sessionFirstTime ?
                    new Date(Date.UTC(
                        session.annexaFormly.model.row2.sessionFirstDate.getFullYear(),
                        session.annexaFormly.model.row2.sessionFirstDate.getMonth(),
                        session.annexaFormly.model.row2.sessionFirstDate.getDate(),
                        session.annexaFormly.model.row2.sessionFirstTime.getUTCHours(),
                        session.annexaFormly.model.row2.sessionFirstTime.getUTCMinutes())) : null,
                sessionDateSecondConvene: session.annexaFormly.model.row3.sessionSecondDate && session.annexaFormly.model.row3.sessionSecondTime ?
                        new Date(Date.UTC(
                            session.annexaFormly.model.row3.sessionSecondDate.getFullYear(),
                            session.annexaFormly.model.row3.sessionSecondDate.getMonth(),
                            session.annexaFormly.model.row3.sessionSecondDate.getDate(),
                            session.annexaFormly.model.row3.sessionSecondTime.getUTCHours(),
                            session.annexaFormly.model.row3.sessionSecondTime.getUTCMinutes())) : null
            }
            if(model.sessionDateFirstConvene && model.sessionDateSecondConvene && model.sessionDateFirstConvene > model.sessionDateSecondConvene){
            	modal.alerts.push({ msg: $filter('translate')('global.sec.literals.firstSessionAfterSecondSession') });
            }else{
            	RestService.insert('./api/sec/session', model)
            	.then(function() {
            		$scope.tableDefinition.reloadInternalData(false, true);
            		modal.close();
            	}).catch(function(error) {
            		if(error && error.data && error.data.message === 'Organ inactive'){
						modal.alerts.push({ msg: $filter('translate')('global.errors.inactiveOrgan') });
					}else{
						modal.alerts.push({ msg: $filter('translate')('global.errors.unknownNew') });
					}
            	});
            }
        }

        $scope.sessionStates = SecFactory.sessionStates;
        $scope.sessionStatesHeader = angular.copy($scope.sessionStates);
        $scope.sessionStatesHeader.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        var getOrganWithPermissions =  $linq(GlobalDataFactory.organs).where(function(x){
        	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
        		var intersectedProfiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
        		if(intersectedProfiles && intersectedProfiles.length > 0){
        			return true;
        		}else{
        			return false;
        		}
        	}else{
        		return false;
        	}
        }).toArray();
        
        $scope.filter = [
        	{ id: 'state', type: 'select-multiple', order: 3, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.sessionStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:(($stateParams.status)?$stateParams.status:HelperService.getPrefilter('secretary','tableSessions','state',$scope.sessionStates,'id')), negated:(($stateParams.status)?false:HelperService.getPrefilterNegated('secretary','tableSessions','state')) },
            { id: 'organ', type: 'select', order: 0, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: getOrganWithPermissions, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','organ',getOrganWithPermissions,'id'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','organ') },
            { id: 'sessionDateFirstConvene', type: 'dateRange', order: 10, label: 'global.sec.literals.sessionDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionDateFirstConvene'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionDateFirstConvene')  },
            { id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionType',GlobalDataFactory.sessionTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionType')  },
            { id: 'sessionNumber', type: 'text', order: 1, label: 'global.sec.literals.sessionNumber', preFilter:HelperService.getPrefilter('secretary','tableSessions','sessionNumber'), negated:HelperService.getPrefilterNegated('secretary','tableSessions','sessionNumber')  }
        ];
        if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
		    var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
		}

        var getFilterCall = function () {
            return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	if($scope.changeStatusFromHeader){
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'state'");
        		if($scope.filterData.state){
        			if(filterAux){
            			var status = $linq($scope.sessionStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
            			filter.state = [status];
           				filterAux.model = filter.state;        		
            		}else{
            			filter.state = undefined;
           		    	filterAux.model = {};
            		}
            	}else{
        			filter.state = undefined;
        		    if(filterAux){
        		    	filterAux.model = {};
        		    }
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filter.state && filter.state.length == 1){
        		if(filter.state[0].id != $scope.filterData.state){
        			$scope.filterData.state = filter.state[0].id;
        		}
        		
        	}else if(!filter.state || filter.state.length != 1){
        		$scope.filterData.state = undefined;
        	}
        	return filter;
        }


        $scope.headerIconSessionStatus = function(icon) {
            if($scope.sessionStates && $scope.sessionStatesHeader.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.sessionStatesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }

            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        var sessionStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconSessionStatus(true)}} {{headerIconSessionStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in sessionStatesHeader | orderBy:\'orderStatus\'" ng-click="setSessionTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setSessionTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#sessions-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }
        
        var sessionStatusRender = function(data, type, full, meta) {
            var content = '';

            if($scope.sessionStates && $scope.sessionStates.length > 0) {
                var state = $linq($scope.sessionStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }

            return content;
        }


        var columnsAux = [
            { id: 'createdDate', visible: false, title:'NotShow' },
            { id: 'state', width: '5%', title: sessionStatusTitle(), render: sessionStatusRender, sortable: false},
            { id: 'organ', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.organ', Language.getActiveColumn()) },
            { id: 'sessionType', width: '15%', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.type', Language.getActiveColumn()) },
            { id: 'sessionDateFirstConvene', width: '10%', column: new DateTimeColumn($filter, 'global.sec.literals.sessionDate')},
            { id: 'sessionNumber', width: '10%', column: new TextColumn($filter, 'global.sec.literals.sessionNumber')},
            { id: 'id', width: '120px', column: new ActionsColumn($filter('translate')('global.literals.actions'), new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'), []), sortable: false }
        ];
        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_session', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_session', columnsAux);
        $scope.tableOrderProperties = {sortName: 'sessionDateFirstConvene', sort: [[4,'desc']]};
        $scope.tableDefinition = {
            id: 'tableSessions',
            origin: 'sec',
            objectType: 'Session',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };

        $scope.viewElement = function(id) {
            $state.transitionTo('annexa.sec.sessions.view', { "session" : id });
        };

		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
    }])
    .controller('SecViewSessionController',['$scope', '$rootScope', 'SecFactory', 'HeaderService', '$state', function ($scope, $rootScope, SecFactory, HeaderService, $state) {
        $scope.session = SecFactory.session;

        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.sessions.view' && SecFactory.session) {
            	$rootScope.headButtons = [];
				$rootScope.headButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt ml-sm-pt', undefined, '#edit-session', 'fa fa-download', 'global.tram.literals.export_dossier', undefined, 'exportDossier'));
            	$rootScope.subHeadButtons = [];
            }
        });

        HeaderService.changeState($state.current, true);
    }])
    .controller('SecDecreesController',['$scope', '$rootScope', 'HeaderService', '$state', 'TableFilterFactory', '$filter', 'Language', 'SecFactory', 'SecModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'DialogsFactory', 'apiAdmin', 'GlobalDataFactory', 'HelperService', 'RestService', '$stateParams', 'SignLocalFactory', function($scope, $rootScope, HeaderService, $state, TableFilterFactory, $filter, Language, SecFactory, SecModals, AnnexaFormlyFactory, RouteStateFactory, DialogsFactory, apiAdmin, GlobalDataFactory, HelperService, RestService, $stateParams, SignLocalFactory) {
        $scope.$on('annexaAdminBaseReloadTable', function(event, args) {
            $scope.tableDefinition.reloadInternalData(false);
        });
        $scope.decree = [];
        $scope.decreeStatesList = angular.copy(SecFactory.decreeStates);
        $scope.decreeStates = angular.copy(SecFactory.decreeStates);
        
        $scope.alerts = [];
        $scope.filterData = {
                state: '',
                loggedUser: $rootScope.LoggedUser.id
        };
         
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.decrees') { 
                $rootScope.subHeadButtons = [new HeadButtonSearch("#tableFilter")]
            }
				$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton mb-xs-pt mr-sm-pt ml-sm-pt', undefined, '#decrees-list', 'fa fa-download', 'global.literals.export', undefined, 'exportList'));
        });

        HeaderService.changeState($state.current);

        $scope.proposalTypes = angular.copy(GlobalDataFactory.proposalTypes);
        $scope.proposalTypes = $linq($scope.proposalTypes).where("x => x.proposalTypeSubtype == 'DECREE'").toArray();
        $scope.proposalTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
        
        $scope.decreeDelegatedTypes = angular.copy(GlobalDataFactory.decreeDelegatedTypes);
        $scope.decreeDelegatedTypes.unshift({id: '', icon: 'minus', style: 'text-black', language1: $filter('translate')('global.literals.all'), language2: $filter('translate')('global.literals.all'), language3: $filter('translate')('global.literals.all'), orderStatus: 0});
        
        $scope.filter = [
        	{ id: 'state', type: 'select', order: 1, label: 'global.literals.state', dataType: 'LOCAL', data: $scope.decreeStates, addAll: true, nameProperty: 'name', callAux: true, preFilter:(($stateParams.status)?$stateParams.status:HelperService.getPrefilter('secretary','tableDecrees','state',$scope.decreeStates,'id')), negated:(($stateParams.status)?false:HelperService.getPrefilterNegated('secretary','tableDecrees','state')) },
        	{ id: 'decreeNumber', type: 'text', order: 2, label: 'global.sec.literals.decreeNumberAbrv', preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeNumber'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeNumber') },
        	{ id: 'proposalType', type: 'select', order: 3, label: 'global.sec.literals.proposalType', dataType: 'LOCAL', data:$scope.proposalTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','proposalType',$scope.proposalTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','proposalType')  },
            { id: 'proposalNumber', type: 'text', order: 4, label: 'global.sec.literals.proposalNumber', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','proposalNumber'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','proposalNumber')  },
            { id: 'decreeDate', type: 'dateRange', order: 5, label: 'global.sec.literals.decreeDate', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeDate'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeDate')  },
            { id: 'extract', type: 'text', order: 6, label: 'global.literals.extract', preFilter:HelperService.getPrefilter('secretary','tableDecrees','extract'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','extract')  },
			{ id: 'dossier', type: 'text', order: 7, label: 'global.literals.dossier', callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','dossier'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','dossier')  },
        	{ id: 'decreeDelegatedType', type: 'select', order: 8, label: 'global.sec.literals.decreeDelegatedTypes', dataType: 'LOCAL', data:$scope.decreeDelegatedTypes , addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, preFilter:HelperService.getPrefilter('secretary','tableDecrees','decreeDelegatedType',$scope.decreeDelegatedTypes,'id'), negated:HelperService.getPrefilterNegated('secretary','tableDecrees','decreeDelegatedType')  }
        ];
 		if($stateParams && $stateParams.filterId){
			if(GlobalDataFactory.userFilters && GlobalDataFactory.userFilters.length > 0){
				HelperService.getFilterByFilterId($scope.filter, $linq(GlobalDataFactory.userFilters).firstOrDefault(undefined,"x => x.id == "+$stateParams.filterId));
				RouteStateFactory.addRouteState($state.current, $scope.filter);
			}
		}else{
		    var routeState = RouteStateFactory.getRouteState($state.current);
	        if(routeState) {
	            $scope.filter = routeState.state;
	        }
		}
        var getFilterCall = function() {
        	return TableFilterFactory.getFilterCall($scope.filter);
        }

        var getFilterCallAux = function () {
        	var filter = TableFilterFactory.getFilterCallAux($scope.filter, true);
        	if($scope.changeStatusFromHeader){
        		var filterAux = $linq($scope.filter).singleOrDefault(undefined, "x => x.id == 'state'");
        		if($scope.filterData.state){
        			if(filterAux){
            			var status = $linq($scope.decreeStates).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");
            			filter.state = {id: status.id, name: status.name};
           				filterAux.model = filter.state;        		
            		}else{
            			filter.state = undefined;
           		    	filterAux.model = {};
            		}
            	}else{
        			filter.state = undefined;
        		    if(filterAux){
        		    	filterAux.model = {};
        		    }
        		}
        		$scope.changeStatusFromHeader = false;
        	}else if(filter.state && filter.state.length == 1){
        		if(filter.state[0].id != $scope.filterData.state){
        			$scope.filterData.state = filter.state[0].id;
        		}
        		
        	}else if(!filter.state || filter.state.length != 1){
        		$scope.filterData.state = undefined;
        	}
        	
        	if($scope.filterData.activeTab && $scope.filterData.activeTab != 'all'){
        		filter.decreeTab = $scope.filterData.activeTab;
        	}
        	
        	return filter;
        }
        
        $scope.decreeStatesHeader = angular.copy($scope.decreeStates);
        $scope.decreeStatesHeader.unshift({
            id: '',
            icon: 'minus',
            style: 'text-black',
            name: $filter('translate')('global.literals.all'),
            orderStatus: 0
        });
        $scope.headerIconDecreeStatus = function(icon) {
            if($scope.decreeStates && $scope.decreeStatesHeader.length > 0 && $scope.filterData.state) {
                var status = $linq($scope.decreeStatesHeader).singleOrDefault(undefined, "x => x.id == '" + $scope.filterData.state + "'");

                if(status) {
                    if(icon) {
                        return status.icon;
                    } else {
                        return status.style;
                    }
                }
            }

            if(icon) {
                return 'minus';
            } else {
                return 'false';
            }
        }
        
        
        var decreeStatusTitle = function() {
            var content = '';
 
            content += '<div>';
            content += '    <div class="btn-group dropdown">';
            content += '        <button type="button" class="btn btn-sm white dropdown-toggle p-l-0" data-toggle="dropdown">';
            content += '            <span class="fa-stack">';
            content += '                <i class="fa fa-{{headerIconDecreeStatus(true)}} {{headerIconDecreeStatus(false)}} text-lg fa-stack-1x" aria-hidden="true"></i>';
            content += '            </span>';
            content += '            <span class="sr-only">{{\'global.literals.showOptions\'}}</span>';
            content += '        </button>';
            content += '        <div class="dropdown-menu">';
            content += '            <a class="dropdown-item" ng-repeat="state in decreeStatesHeader | orderBy:\'orderStatus\'" ng-click="setDecreeTypeDT(state);">';
            content += '                <span class="fa-stack">';
            content += '                    <i class="fa  fa-{{state.icon}} {{state.style}} text-lg fa-stack-1x"></i>';
            content += '                </span>';
            content += '                &nbsp;&nbsp;<span translate="global.literals.all" ng-if="state.id == \'\'"></span>';
            content += '                &nbsp;&nbsp;<span translate="{{state.name}}" ng-if="state.id != \'\'"></span>';
            content += '            </a>';
            content += '        </div>';
            content += '    </div>';
            content += '</div>';

            return content;
        }
        
        $scope.setDecreeTypeDT = function(state){
            $scope.filterData.state = ((!state.id || state.id == 0) ?  '' : state.id);
            if($scope.tableDefinition){ 
            	$scope.changeStatusFromHeader = true;
            	$scope.tableDefinition.reloadInternalData(true, true);
            }else{
                var scopeAux = angular.element('#decrees-list').scope();
                if(scopeAux && scopeAux.tableDefinition && scopeAux.tableDefinition.reloadInternalData){
                    scopeAux.tableDefinition.reloadInternalData(true, true);
                }
            }
        }
  
        
        var decStatusRender = function(data, type, full, meta) {
            var content = '';
            if($scope.decreeStates && $scope.decreeStates.length > 0) {
                var state = $linq($scope.decreeStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");
                if(state) {
                    content += '<div class="text-center">';
                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                    content += '    </span>';
                    content += '</div>';
                }
            }
            return content;
        }

        var dossierRender = function(data, type, full, meta) {
            var content = '';
            if(data && data.dossier && data.dossier.dossierNumber) {
            	content = '<a class="text-primary" href="" ui-sref="annexa.tram.pending.viewdossier({dossier: '+data.dossier.id+', dossierTransaction: 0})" target="_blank">'+data.dossier.dossierNumber+'</a>';
            }
            return content;
        }
        
        var proposalNumberRender = function(data, type, full, meta) {
            var content = '';
            if(data && data.proposalNumber && data.id) {
            	content = '<a class="text-primary" href="" ui-sref="annexa.sec.decrees.edit({decrees: '+data.id+'})" target="_blank">'+data.proposalNumber+'</a>';
            }
            return content;
        }
        
        var showSendToSign = function(data, type, full, meta){
        	if(full.status && _.contains(['PENDING'], full.status)){
        		return true;
        	}else{
        		return false;
        	}
        }

        var columnsAux = [
        	{ id: 'status', width: '1%', title: decreeStatusTitle(), render: decStatusRender, sortable: false },
            { id: 'proposal.proposalType', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.proposalType', Language.getActiveColumn()) },
        	{ id: 'decreeNumber', column: new TextColumn($filter, 'global.sec.literals.decreeNumberAbrv') },
        	{ id: 'decreeDate', width: '12%', column:  new DateColumn($filter, 'global.sec.literals.decreeDate') },
        	{ id: 'extract', column:  new TextColumn($filter, 'global.literals.extract') },
            //{ id: 'proposal.proposalNumber', column: new TextColumn($filter, 'global.sec.literals.proposalNumber') },
        	{ id: 'proposal', title: $filter('translate')('global.sec.literals.proposalNumber'), render: proposalNumberRender },
			{ id: 'proposal.createdTransaction', title: $filter('translate')('global.literals.dossier'), render: dossierRender},
            { id: 'decreeDelegatedType', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.decreeDelegatedTypes', Language.getActiveColumn()) },
            { id: 'id', width: '8%', column: new ActionsColumn($filter('translate')('global.literals.actions'), 
            		new ActionButton('global.literals.see', 'viewElement([data])', 'fa-eye'),[
            			new ActionButton('global.literals.sendToSignValidate','sendToSign([data])',undefined, undefined, showSendToSign)
            		]),sortable: false }
        ];

        var datatableSettings = $rootScope.app.configuration.datatables_settings;
        var columns = getDatatableColumnsSettings(datatableSettings, 'datatable_sec_decree', columnsAux);
        var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_sec_decree', columnsAux);
        $scope.tableOrderProperties = {sortName: 'decreeDate', sort: [[3,'desc']]};
        if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > -1){
            $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
        }
        $scope.tableDefinition = {
            id: 'tableDecrees',
            origin: 'sec',
            objectType: 'Decree',
            sortName: $scope.tableOrderProperties.sortName,
            sort: $scope.tableOrderProperties.sort,
            filterCall: getFilterCall(),
            filterCallAux: getFilterCallAux(),
            filterCallFunc: getFilterCall,
            filterCallAuxFunc: getFilterCallAux,
            columns: columns,
            containerScope: $scope,
			notAddExcel: true
        };
        
        $scope.sendToSign = function (id){
        	var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
        	var decree = (($scope.tableDefinition && $scope.tableDefinition.list)?$linq($scope.tableDefinition.list).firstOrDefault(undefined, "x=> x.id == "+id):undefined);
        	if(decree && decree.documents && documentType){
        		_.forEach(decree.documents, function(document){
        			RestService.findOne('Document', document.document.id).then(function(data) {
            			SignLocalFactory.sendToSignModal(undefined, [JSOG.decode(data.data)]);
                    });
        		});
        	}
        }

        $scope.viewElement = function(id) {
            $state.transitionTo('annexa.sec.decrees.edit', { "decrees" : id});
        };

		$scope.exportList = function(){
			DialogsFactory.confirm('global.querys.execute.export', 'global.querys.execute.exportConfirm')
            .then(function (data) {
				RestService.exportData($scope.tableDefinition);
           }).catch(function (data) {
	               //Empty
	       });
		}
        
    }])
    .controller('SecEditDecreeController', ['$scope', '$rootScope', 'HeaderService', '$state', 'AnnexaEntityFactory', 'SecFactory', 'DccumentsFactory', 'GlobalDataFactory', function ($scope, $rootScope, HeaderService, $state, AnnexaEntityFactory, SecFactory, DccumentsFactory, GlobalDataFactory) {
        $scope.decree = SecFactory.decree;
                
        HeaderService.onChangeState($scope, function(message) {
            if(message.state.name == 'annexa.sec.decrees.edit' && SecFactory.decree) {
            	$rootScope.subHeadButtons = [];
            	var canSendToSign = false;
            	var canEditDocument = false;
            	var canAnnul = false;
                var documentType = $rootScope.app.configuration.sec_document_types["SDR"];
	        	if($scope.decree && $scope.decree.documents && documentType){
	        		if($scope.decree.status && _.contains(['PENDING'], $scope.decree.status)){
		        		_.forEach($scope.decree.documents, function(document){
		        			if(document.document && document.document.type && _.contains(documentType.id, document.document.type.id+"")){
		        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED', 'COMPLETE'], document.document.docStatus)){
		        					canSendToSign = true;
		        				}
		        				if(document.document.docStatus && _.contains(['NEW_AND_ATTACHMENT','REJECTED'], document.document.docStatus)){
		        					canEditDocument = true;
		        				}
		        			}
		        		});
	        		} else if($scope.decree.status && _.contains(['SIGNED'], $scope.decree.status)) {
	        			canAnnul = true;
	        		}
	        	}
	        	if(canSendToSign){
	        		var diligenceActions = [];
	 				$rootScope.subHeadButtons.push(new HeadButton('btn-group dropdown breadDivButton m-x-xs', undefined, '#edit-proposal', undefined, 'global.literals.actions', undefined, undefined, undefined, undefined, undefined, diligenceActions, 'dropdown-menu primary pull-right'));
	 				diligenceActions.push(new HeadButton('btn primary breadDivButton', undefined, '#edit-decree', undefined, 'global.sec.streamline.amendmentProposal', undefined, 'generateAmendment'));
	 				diligenceActions.push(new HeadButton('btn primary breadDivButton', undefined, '#edit-decree', undefined, 'global.literals.cancel', undefined, 'cancelProposal'));
	             	
	        		$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-decree', undefined, 'global.literals.sendToSignValidate', undefined, 'sendToSign'));
				}
                if(canEditDocument){
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-decree', undefined, 'global.sec.literals.editDecree', undefined, 'editDecreeDocument'));
                }
                if(canAnnul) {
                	$rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton m-x-xs', undefined, '#edit-decree', undefined, 'global.sec.literals.annulDecree', undefined, 'annulDecree').setPermissions('annul_decree'));
                }
            }
        });

        HeaderService.changeState($state.current, true);        
    }])
;